import { connect } from 'react-redux';
import App from '../../components/App';
//import { getInfo, getConfig, select, updateConfig } from '../../actions';
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  //
});
 
export default connect(mapStateToProps, mapDispatchToProps)(App); 
