import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
//import { useSelector } from 'react-redux'
const Navbar = () => {
	return (
		<header className="header">
			<div className='header-links'>
				<Link to='/' className='header-links-text'>LA GUILDE</Link>
				<Link to='/membres' className='header-links-text'>SES MEMBRES</Link>
				<Link to='/son-histoire' className='header-links-text'>SON HISTOIRE</Link>
				<Link to='/liens' className='header-links-text'>SES LIENS</Link>
			</div>
		</header>
	);
};
Navbar.propTypes = {};
export default Navbar;