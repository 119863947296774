import React from 'react';//, { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Home = () => {
   return (
      <div className='home'>
         <h1 className='home-title'>Meltin*pot</h1>
         {/*<div className='temporaire'></div>*/}
         {/*<iframe className='home-iframe' width="720" height="480" src="https://www.youtube-nocookie.com/embed/xvFZjo5PgG0" title="YouTube video player" frameBorder="0" allowFullScreen></iframe>*/}
         <br/>
         <div className='home-about'>
            La famille Meltin*pot est une guilde du serveur 1 sur le mmo-rpg Nostale réputée pour son fairplay et son classement !
            <br/>
            Elle a toujours fait partie du top 20 en fxp et elle existe depuis la création du premier serveur, en 2006.
            <br/>
            <br/>
            Souvent considérée à raison comme famille élitiste, Meltin*pot est très probablement la meilleure de sa communauté :
            <br/>
            Elle se compose pour la majorité d'anciens et gros joueurs, qui même s'ils sont fatigués sont toujours là...
            <br/>
            <br/>
            Et puis on recrute parfois, même si c'est très difficile d'entrer chez nous...
            <br/>
            On s'organise comme on peu pour s'organiser en s'organisant. Vous trouverez plus d'info en allant à la page "son histoire"...
            <br/>
            <br/>
            On est plutôt pve mais on apprécie aussi le pvp sauvage (coucou nikolak) et les duels avec des zéro !
         </div>
      </div>
    );
};

Home.propTypes = {};

export default Home;
