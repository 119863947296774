import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
const Footer = () => {
	/*
	setInterval(() => {
		let c = [
            `#ff0000`,
			`#ed0000`,
			`#dc143c`,
			'#b22222',
			'#8b0000',
			'#e41100',
			'#8e000b',
			'#e61503',
			'#e51301',
			'#e41100',
        ];
		//console.log(' Hi everyone!', 'color: #1c87c9; font-size: 18px'); 
        console.log(`%c Never paste anything here !`, `color: ${c[Math.floor(Math.random() * c.length)]}; font-size: 18px`);
	}, 1000);
	*/
	return (
		<footer className="footer">
			<div className='footer-links'>
				<Link to='/ressources' className='footer-links-text'>SES RESSOURCES</Link>
				<Link to='/recrutement' className='footer-links-text'>SON RECRUTEMENT</Link>
				<Link to='/cgu' className='footer-links-text'>LES CGU</Link>
				<Link to='/login' className='footer-links-text'>LOGIN</Link>
			</div>
		</footer>
	)
};
Footer.propTypes = {};
export default Footer;