import React from 'react';//, { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Recrutement = () => {
   return (
      <div className='recrutement'>
         <p>archers - fermé
         <br/>
         <br/>
         mages - fermé
         <br/>
         <br/>
         épéistes - fermé
         <br/>
         <br/>
         artistes martiaux - fermé</p>
      </div>
    );
};
Recrutement.propTypes = {};
export default Recrutement;
