import { Routes, Route } from 'react-router-dom'; // , Navigate
import React from 'react';//, { useEffect } 

import Home from '../../containers/Home';
import Navbar from './Navbar';
import Footer from './Footer';
import La404 from './La404';
import SonHistoire from './SonHistoire';
import Liens from './Liens'
import './style.scss';
import Recrutement from './Recrutement';
//import SesMembres from './SesMembres'
import Cgu from './Cgu'
const App = () => {
  /*
  useEffect(getInfo, [getInfo]);
  const handleClick = () => {};
  */
  return (
    <div className="app"> {/* onClick={handleClick}*/}
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />}/>
        {/*<Route exact path='/membres' element={<SesMembres />}/>*/}
        <Route exact path='/son-histoire' element={<SonHistoire />}/>
        <Route exact path='/liens' element={<Liens />}/>
        <Route exact path='/recrutement' element={<Recrutement />}/>
        <Route exact path='/cgu' element={<Cgu />}/>
        {/*<Route path="/*" element={<Navigate to="/"/>}/>*/}
        <Route path='/*' element={<La404 />}/>
        <Route element={<Home />}/>
      </Routes> 
      <Footer />
    </div>
  );
};

App.propTypes = {};

export default App;
