import React from 'react';//, { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Recrutement = () => {
   return (
      <div className='recrutement'>
         <h3 className='recrutement-title'><br/>Préambule</h3>
         <p className='recrutement-text'>Conformément aux dispositions des articles 6-III et 19 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, dite LCEN, les Utilisateurs du site meltin-pot.fr les présentes mentions légales : La connexion et la navigation sur le site meltin-pot.fr par l'Utilisateur implique l'acceptation entière et sans réserve des présentes mentions légales et de nos conditions d'utilisation. Celles-ci sont accessibles sur le site dans la rubrique « CGU ».</p>

         <h5 className='recrutement-title'>ARTICLE 1 : L'éditeur</h5>
         <p className='recrutement-text'>L'édition et la direction de la publication du site meltin-pot.fr sont assurées par Earlam et Auron.</p>

         <h5 className='recrutement-title'>ARTICLE 2 : L'hébergeur</h5>
         <p className='recrutement-text'>L'hébergement du site meltin-pot.fr est assuré par Earlam.</p>

         <h5 className='recrutement-title'>ARTICLE 3 : Accès au site</h5>
         <p className='recrutement-text'>Le site est accessible de n'importe quel endroit, 7 jours sur 7, 24 heures sur 24, sauf cas de force majeure, interruption programmée ou non et pouvant résulter d'une nécessité de maintenance.<br/>En cas de modification, interruption ou suspension des services, le site meltin-pot.fr ne pourra être tenu pour responsable.</p>

         <h5 className='recrutement-title'>ARTICLE 4 : Collecte des données</h5>
         <p className='recrutement-text'>Le site meltin-pot.fr est exempté de déclaration auprès de la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte pas de données concernant ses utilisateurs...</p>

         <h5 className='recrutement-title'>ARTICLE 5 : Cookies</h5>
         <p className='recrutement-text'>Nous n'utilisons pas de cookies sur ce site :)</p>

         <h5 className='recrutement-title'>ARTICLE 6 : Propriété intellectuelle</h5>
         <p className='recrutement-text'>Toute utilisation, reproduction, diffusion, commercialisation, modification de tout ou partie du site meltin-pot.fr, sans l'autorisation de l'Editeur (Earlam) est interdite<br/>et pourra donner lieu à des poursuites judiciaires comme prévues notamment par le Code de la propriété intellectuelle et le Code civil.</p>
      </div>
   );
};
Recrutement.propTypes = {};
export default Recrutement;
