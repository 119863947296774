import React from 'react';
//import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import App from './containers/App';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from '../src/store';
const container = document.getElementById('root'); 
const root = createRoot(container);
/*
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
*/
//
const rootReactElement = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
//const target = document.getElementById('root');
root.render(rootReactElement);