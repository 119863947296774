import React from 'react';//, { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const La404 = () => {
   return (
      <div className='la404'><h1 className='la404-title'>404</h1></div>
   );
};

La404.propTypes = {};

export default La404;
