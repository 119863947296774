import React from 'react';//, { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Liens = () => {
   return (
      <div className='liens'>
         <div className='liens-box'>
            <a href='https://discord.gg/ufxKRpn6en' target='_blanc'><img className='liens-box-img' width='200px' alt='' src='https://www.aht.li/3753563/Discord_Icon.png'></img></a>
            <a href='https://discord.gg/ufxKRpn6en' target='_blanc'><img className='liens-box-img' width='200px' alt='' src='https://www.aht.li/3753567/youtube-icon.png'></img></a>
            <a href='https://discord.gg/ufxKRpn6en' target='_blanc'><img className='liens-box-img' width='200px' alt='' src='https://www.aht.li/3753564/twitter-icon.png'></img></a>
         </div>
      </div>
    );
};
Liens.propTypes = {};
export default Liens;
