//import axios from 'axios';
//import stringSimilarity from 'string-similarity'
//import { GET_INFO, storeInfo, TOTO, storeToto, GET_LOGS, storeLogs, UPDATE_VOCAL, SECOND, GET_FEEDBACK, storeFeedback, GET_TAGS, storeTags, SEARCH_BY_SIMILARITY, storeSimilarity } from '../actions';
//import apiBaseURL from './baseURL';
//import apiSecondURL from './secondURL';
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


//const api = axios.create({baseURL: apiBaseURL});


const homeMiddleware = (store) => (next) => (action) => {
  //console.log(api)
  switch (action.type) {
    /*
    case GET_INFO:
      api.get('/v1/dashboard/bot')
        .then((response) => {
          store.dispatch(storeInfo(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      break;
    case TOTO:
      api.get(`/v1/dashboard/auth?code=${action.toto}`)
        .then((response) => { 
          store.dispatch(storeToto(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      break;
    case SECOND:
      api2.get(`/v1/dashboard/auth?code=${action.toto}&secret=${action.secret}`)
        .then((response) => {
          console.log(response.data);
          //store.dispatch(storeToto(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      break;
    case UPDATE_VOCAL:
      let { home } = store.getState();
      let zeToken = home.user.toto;
      axios(`https://discord-api.shiro.fr/v1/dashboard/guild?toto=${zeToken}`, {
        method: 'patch',
        headers: {},
        data: { id: `${home.selected.guild_id}`, vocal: `${home.selected.vocal}` }
      })
        .catch((error) => {
          console.error(error);
        });
      break;
    case GET_LOGS:
      let token = action.token;
      if (!token) return;
      api.get(`/v1/dashboard/logs?toto=${token}`)
        .then((response) => {
          return store.dispatch(storeLogs(response.data));
        })
        .catch((error) => {
          console.error(error); 
        });
      break;
    case GET_FEEDBACK:
      let totoken = action.token;
      let guild = action.guild;
      if (!totoken||!guild) return;
      api.get(`/v1/dashboard/feedback/tags?toto=${totoken}&server=${guild}`)
        .then((response) => {
          api.get(`/v1/dashboard/feedback?toto=${totoken}`)
            .then((responseTwo) => {
              return store.dispatch(storeFeedback(response.data, responseTwo.data));
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });

      break;
    case GET_TAGS:
      let tokenken = action.token;
      let serv = action.guild;
      if (!tokenken) return;
      api.get(`/v1/dashboard/feedback/tags?toto=${tokenken}&server=${serv}`)
        .then((response) => {
          return store.dispatch(storeTags(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      break;
      case SEARCH_BY_SIMILARITY:
        let feedback = action.feedback;
        let list = action.list;
        let taglist = action.tags;
        let feedbacklist = [];
        let feedbackTags = [];
        list.forEach(f => {
          let regexList = [];
          taglist.forEach(t => {let reg = new RegExp(`<@&${t.tag_id}>`);regexList.push({ regex: reg, name: `${t.tag_name}`, id: `${t.tag_id}`, color: `${t.tag_color}`})})
          let pushTagId = function(i) { feedbackTags.push(regexList[i].id) }
          regexList.some(({ regex }, index) => (regex.test(f.msg_content) && pushTagId(index)))
          let similarity = stringSimilarity.compareTwoStrings(feedback.msg_content.toLowerCase(), f.msg_content.toLowerCase());
          if (f.msg_id !== feedback.msg_id) {
            feedbacklist.push({ msg_content: f.msg_content, msg_id: f.msg_id, msg_url: f.msg_url, user_tag: f.user_tag, user_avatar:f.user_avatar, guild_id: f.guild_id, last_update: f.last_update, vote_yes: f.vote_yes, vote_no: f.vote_no, similarity: (similarity*100).toFixed(2), tags: feedbackTags })
          }
        })
        let feedbackListSorted = feedbacklist.sort((a, b) => (a.similarity < b.similarity ? -1 : (a.similarity > b.similarity ? 1 : 0)))
        store.dispatch(storeSimilarity(feedback, feedbackListSorted));
      break;
      */
    default:
      next(action);
  }
};

export default homeMiddleware;
